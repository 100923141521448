.home-page-container{
    display: grid;
    place-items: center;
    width: 100%;
    height: 89.9vh;
    background-image: url("../../assets/images/rauladmin.JPG");
    background-size: cover;
    background-repeat: no-repeat;
}

#welcomeMessage{
    padding-top: 6%;
    font-size: 75px;
    color: white;
    font-weight: 800;

}

.home-option-text{
    color: whitesmoke;
    font-size: 26px;
    margin-bottom: 12px;
    text-align: center;
}

.home-login-button{
    display: inline;
    padding: 5px;
    border-radius: 99999px;
    width: 350px;
    border: 3px solid #dee58d;
    background-color: #4060a1cc;
    color: whitesmoke;
    font-size: 48px;
    margin-left: 24px;
}

.home-login-button:hover{
    background-color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    color: #4060a1;
    font-weight: 700;

}

.home-container-buttons{
    margin: 0 auto;
}

.home-button-junta{
    font-size: 24px;
    margin-left: 115px;
    width: 100px;
}

.home-user-is-back{
    width: 750px
}