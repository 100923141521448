.juntas-container {
  position: relative;
  display: flex;
  flex-direction: row;
  place-items: center;
}

.juntas-control-panel {
  display: flex;
  flex-direction: row;
  place-items: start end;
  width: 180px;
  min-width: 180px;
  height: 100vh;
  padding-left: 20px;
  padding-top: 30px;
  margin-bottom: 40%;
  color: #dee58d;
}

.juntas-control-panel ul li {
  text-align: left;
  list-style: none;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

.juntas-control-panel ul > li {
  margin-top: -15px;
  margin-bottom: 50px;
}

.iconButton {
  text-align: center;
  margin-right: 10px;
  font-size: 16px;
}

.juntas-vista-panel {
  width: 75vw;
  padding-left: 15px;
  border-left: 3px solid #dee58d;
}

#videoconferencia {
  width: 83.5vw;
}

.junta-form-action {
  border: 0;
  width: 83.5vw;
  height: 99vh;
}

#videoconferencia,
.junta-form-action {
  margin: 0 auto;
}
