#MobileViewGeneral {
  padding: 5px 15px;
  color: whitesmoke;
}

#mobilePageView {
  display: grid;
  place-items: center;
  padding: 5px;
}

#mobilePageView h2 {
  margin-bottom: 8px;
  font-size: 44px;
}

#mobile-user-text{
  text-align: center;
  padding: 5px 15px;
  color: whitesmoke;
}

.mobile-button-user{
  margin: 20px;
  padding: 15px;
  border-radius: 99999px;
  width: 110px;
  border: 3px solid #dee58d;
  background-color: #4060a1cc;
  color: whitesmoke;
  font-size: 24px;
}

.bUser {
  display: block;
  width: 250px !important;
}

#mobilePageView select {
  display: inline-block;
  height: 30px;
  width: 250px;
  padding: 2px 10px 2px 2px;
  margin: 10px;
  outline: none;
  color: #74646e;
  border: 1px solid #c8bfc4;
  border-radius: 4px;
  box-shadow: inset 1px 1px 2px #ddd8dc;
  background: #fff;
  text-align: center;
}

#contBuMultiMobile {
  text-align: center;
}

.bStoreMobile {
  display: inline-block;
  margin: 15px;
  height: 55px;
}

.bAsistenciaMobile {
  margin: 20px;
  padding: 15px;
  border-radius: 99999px;
  width: 110px;
  border: 3px solid #dee58d;
  background-color: #4060a1cc;
  color: whitesmoke;
  font-size: 24px;
}

#iframeMobileView {
  border: 0;
  width: 100vw;
  height: 99vh;
  margin-left: -15px;
}
