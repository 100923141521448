.navbar-container {
  width: 100vw;
  height: 80px;
  background-image: url("../../../../assets/images/navbar.png");
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo-container {
  width: 180px;
}

.app-logo {
  width: 180px;
}

.navbar-menu-list {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.navbar-menu-list h3 {
  color: #dee58d;
  margin-top: 4px;
}

.navbar-menu-list-item {
  margin-left: 1px;
  margin-bottom: 5px;
}

.navbar-menu-list-item a {
  padding: 7px;
  color: whitesmoke;
  text-decoration: none;
  display: inline-block;
  margin-left: 8px;
}

.navbar-menu-list-item a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-menu-list-item button {
  padding: 5px;
  border-radius: 99999px;
  width: 80px;
  border: 3px solid #dee58d;
  background-color: #4060a1cc;
  color: whitesmoke;
  font-size: 14px;
}

.navbar-menu-list-item button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  color: #4060a1;
  font-weight: 700;
}
