#Propietario {
  display: flex;
  flex-direction: row;
  place-items: center;
}

#botonesPropietario {
  width: 250px;
  min-width: 250px;
  height: 99vh;
  padding-left: 20px;
  padding-top: 50px;
  border-right: 3px solid #dee58d;
  color: #dee58d;
}

#botonesPropietario ul li {
  text-align: left;
  list-style: none;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

#botonesPropietario ul > li {
  margin-top: 8px;
  margin-bottom: 50px;
}

.iconButton {
  text-align: center;
  margin-right: 10px;
  font-size: 16px;
}

.dashboard-prop-alignRight{
  float: right;
  margin-right: 15px;
}

.dropdownList {
  width: 98%;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #4060a1;
  border-radius: 15px;
  padding-top: 15px;
  text-align: center !important;
  position: absolute;
  top: -20px;
  left: 100%;
}

.dropdownList > li {
  padding: 10px;
  padding-left: 15px;
  margin-bottom: 12px !important;
  font-weight: bold;
}

.dropdownList > li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#multiVoto {
  position: absolute;
  width: 100%;
}

#multiVoto div {
  position: relative;
  width: 100%;
}

#multiVoto h3 {
  text-align: center;
  width: 78%;
  color: white;
  font-size: 36px;
  margin-top: 25px;
  margin-bottom: 25px;
}

#multiVoto div > div {
  text-align: center;
  width: 78%;
}

.bAsistencia {
  margin: 20px;
  padding: 15px;
  border-radius: 99999px;
  width: 220px;
  border: 3px solid #dee58d;
  background-color: #4060a1cc;
  color: whitesmoke;
  font-size: 24px;
}

.bAsistencia:hover {
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  color: #4060a1;
  font-weight: 700;
}

#contFormPropietario {
  width: 75%;
}

#contFormPropietario iframe {
  border: 0;
  width: 100%;
  height: 99vh;
  padding-bottom: 25px;
}

.propietario-espacio-vacio{
  margin-left: 30px  
}

.propietario-flecha-desplegable{
  margin-left: 35%
}