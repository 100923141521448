.reset-container{
    position: relative;
    margin: 0 auto;
    margin-top: 25px;
    padding: 25px;
    border: 2px solid #dee61a;
    border-radius: 5%;
    box-shadow: rgb(51, 51, 51) 10px 10px;
    width: 350px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.reset-container form section{
    margin-top: 15px;
}

.reset-button{
    position: absolute;
    bottom: 0.3em;
    right: 2em;
    padding: 15px;
    margin-top: 50px;
    border-radius: 99999px;
    width: 16em;
    border: 3px solid #dee58d;
    background-color: #4060a1cc;
    color: whitesmoke;
    font-size: 20px;
}

.reset-button:hover{
    background-color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    color: #4060a1;
    font-weight: 700;
}

.reset-forgot-password{
    text-decoration: none;
    color: white;
    cursor: pointer;
    
}

.reset-input{
    width: 100%;
    padding: 6px 20px;
    margin: 8px 0;
    margin-left: -20px;
    border-radius: 25px;
    background-color: rgb(206, 206, 206);
}

.reset-input::placeholder{
    font-style: italic;
    color:black
}

.reset-error-container{
    width: 250px;
    color: goldenrod;
    font-size: 24px;
    font-weight: bolder;
}