
.login-navbar-container{
    width: 100vw;
    height: 80px;
    background-image: url("../../assets/images/navbar.png");
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-navbar-logo{
    width: 180px;
}

.login-container{
    margin: 0 auto;
    margin-top: 25px;
    padding: 25px;
    border: 2px solid #dee61a;
    border-radius: 5%;
    box-shadow: rgb(51, 51, 51) 10px 10px;
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-button{
    padding: 15px;
    margin-top: 25px;
    border-radius: 99999px;
    width: 120px;
    border: 3px solid #dee58d;
    background-color: #4060a1cc;
    color: whitesmoke;
    font-size: 20px;
}

.login-button:hover{
    background-color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    color: #4060a1;
    font-weight: 700;
}

.login-forgot-password{
    text-decoration: none;
    color: white;
    cursor: pointer;
    
}