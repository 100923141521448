#AdComunidades {
  display: flex;
  flex-direction: row;
  place-items: center;
}

#botonesAdComunidad {
  width: 340px;
  min-width: 340px;
  height: 99vh;
  padding-left: 20px;
  padding-top: 50px;
  border-right: 3px solid #dee58d;
  color: #dee58d;
}

#botonesAdComunidad ul li {
  text-align: left;
  list-style: none;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

#botonesAdComunidad ul > li {
  margin-top: -15px;
  margin-bottom: 50px;
}

.iconButton {
  text-align: center;
  margin-right: 10px;
  font-size: 16px;
}

.dashboard-admin-dropdown-menu {
  width: 98%;
  padding-top: 30px;
  margin-bottom: 15px !important;
  position: absolute;
  top: -75px;
  left: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  color: #4060a1;
  border-radius: 15px;
}

.dashboard-admin-dropdown-menu > li {
  padding: 5px;
}

.dashboard-admin-dropdown-menu > li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dashboard-admin-dropdown-menu-item{
  text-align: left !important;
  margin-left: 15px;
  margin-bottom: 25px !important;
}

.dashboard-admin-dropdown-menu-item:hover {
  padding: 12px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.1);
}

.administrador-flecha-desplegable{
  margin-left:35%;
}

.dashboard-admin-alignRight{
  float: right;
  margin-right: 15px;
}

#contFormAdComunidad {
  width: 75%;
  text-align: center;
}

#contFormAdComunidad iframe {
  border: 0;
  width: 100%;
  height: 99vh;
  padding-bottom: 50px;
}

.dashboard-admin-document-download-container{
  position: absolute;
  bottom: 170px;
  right: 120px;
}

.dashboard-admin-document-example-download-button{
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 15px;
  border: 3px solid #dee58d;
  border-radius: 999px;
  margin-left: 25px;
}

.dashboard-admin-document-example-download-button:hover{
  color: #4060a1;
  background-color: whitesmoke;
  font-weight: bold;
}
