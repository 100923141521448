#IniciarJunta {
  flex-direction: column;
}

#videoconferencia {
  width: 100%;
}

#videoconferencia iframe {
  height: 600px;
}

.formConferences {
  padding-top: 25px;
  border: 0;
  width: 100%;
  height: 99vh;
  flex-shrink: 0;
  overflow: hidden;
}

#contMenuJunta {
  width: 98vw;
}

#menuJunta {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  border-bottom: 2px solid #dee58d !important;
}

#menuJunta li {
  display: inline-block;
  margin: 20px;
}

#iframeMenuJunta {
  border-bottom: 2px solid #dee58d !important;
}

.not-displayForm {
  display: none;
}

.displayForm {
  display: initial;
}
