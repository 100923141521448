@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: -apple-system, Montserrat, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  background-color: #4060a1 !important;
}
