.home-navbar-container{
    width: 100vw;
    height: 80px;
    background-image: url("../../../../assets/images/navbar.png");
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-navbar-logo{
    width: 180px;
}