.input-login-container{
    margin-top: 5px;
}

.input-login{
    width: 100%;
    padding: 6px 20px;
    margin: 8px 0;
    margin-left: -20px;
    border-radius: 25px;
    background-color: rgb(206, 206, 206);
}

.input-login::placeholder{
    font-style: italic;
    color:black
}