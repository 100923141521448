.junta-propietarios-dropdown-list {
  width: 98%;
  padding-top: 30px;
  margin-bottom: 15px !important;
  position: absolute;
  top: -75px;
  left: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  color: #4060a1;
  border-radius: 15px;
}

.junta-propietarios-dropdown-list > li {
  padding: 5px;
}

.junta-propietarios-dropdown-list > li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.junta-propietarios-dropdown-list-item {
  text-align: center !important;
  margin-bottom: 25px !important;
}

.junta-propietarios-dropdown-list-item:hover {
  padding: 12px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.1);
}